<template>
  <div class="bets">
    <div class="bets-items clearfix">
      <div :class="['bets-item', betsLayout]"
           :key="bet.uid"
           v-for="bet in bets">
        <component :is="renderComponent"
                   :bet="bet"
                   :is-system="isSystem"
                   :color="color">
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import { startCase } from 'lodash';
import config from '../../config';

export default {
  name: 'PreviewBets',
  components: {
    TicketPreviewBetSport: () => import('./sportsbook/TicketPreviewBetSport'),
    TicketPreviewBetCommon: () => import('./TicketPreviewBetCommon'),
  },
  props: {
    bets: {
      type: Array,
      default() {
        return [];
      },
    },
    renderType: {
      type: String,
    },
    isSystem: {
      type: Boolean,
    },
  },
  data() {
    return {
      color: config.color,
      betsLayout: config.betsLayout,
    };
  },
  computed: {
    renderComponent() {
      return `TicketPreviewBet${startCase(this.renderType)}`;
    },
  },
  updated() {
    this.scrollToBottom();
  },
  mounted() {
    this.scrollToBottom();
  },
  methods: {
    scrollToBottom() {
      const betsContainer = this.$el.querySelector('.bets-items');
      betsContainer.scrollTop = betsContainer.scrollHeight;
    },
  },
};
</script>

<style lang="scss">
.ticket-preview {
  .bets {
    color: #fdfdfd;
    height: 100%;
    font-size: 14px;
  }

  .bets-items {
    height: 100%;
    padding: 1px;
    overflow: auto;
  }

  .bets-item {
    float: left;
    padding: 1px;

    &.cards {
      width: 25%;
      height: 89px;
    }

    &.list {
      width: 100%;
      display: flex;

      & > div {
        width: 100%;
      }
    }
  }

  .bet {
    color: #161616;
    background-color: #f5f5f5;
    height: 100%;
    border-radius: 3px;
  }

  &.horizontal {
    .bets {
      padding-bottom: 110px;
    }

    .popup-content {
      width: 100%;
    }
  }

  &.sidebar {
    .bets {
      width: 75%;
    }
    .bets-item {
      width: 33.3%;
    }

    .popup-content {
      width: 75%;
    }
  }
}

@media screen and (max-width: 600px) and (max-height: 800px) {
  .bets-item {
    &.cards {
      width: 33.33% !important;
    }
  }
  .bet-heading {
    .bet-display-id {
      font-size: 14px !important;
    }

    .bet-time {
      font-size: 10px !important;
      line-height: 1.5;
    }
  }

  .bet-content {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 800px) and (max-height: 600px) {
  .bet-heading {
    .bet-display-id {
      font-size: 14px !important;
    }

    .bet-time {
      font-size: 10px !important;
      line-height: 1.5;
    }
  }

  .bet-content {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1024px) and (max-height: 768px) {
  .bet-heading {
    .bet-display-id {
      font-size: 16px !important;
    }

    .bet-time {
      font-size: 12px !important;
      line-height: 1.4;
    }
  }

  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 768px) and (min-height: 1024px) {
  .bets-item {

    &.cards {
      width: 33.33% !important;
    }
  }
  .bet-heading {
    .bet-display-id {
      font-size: 16px !important;
    }

    .bet-time {
      font-size: 12px !important;
      line-height: 1.4;
    }
  }

  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1024px) and (min-height: 1280px) {
  .bets-item {
    &.cards {
      width: 33.33% !important;
    }
  }

  .bet-heading {
    .bet-display-id {
      font-size: 16px !important;
      line-height: 1.1 !important;
    }

    .bet-time {
      font-size: 12px !important;
      line-height: 1.4;
    }
  }

  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1280px) and (min-height: 1024px) {
  .bet-heading {
    .bet-display-id {
      font-size: 16px !important;
      line-height: 1 !important;
    }

    .bet-time {
      font-size: 12px !important;
      line-height: 1.4;
    }
  }

  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1600px) and (min-height: 900px) {
  .bet-heading {
    .bet-display-id {
      font-size: 16px !important;
    }

    .bet-time {
      font-size: 12px !important;
      line-height: 1.4 !important;
    }
  }

  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 900px) and (min-height: 1600px) {
  .bets-item {
    &.cards {
      width: 50% !important;
    }
  }

  .bet-heading {
    .bet-display-id {
      font-size: 16px !important;
    }

    .bet-time {
      font-size: 12px !important;
      line-height: 1.4 !important;
    }
  }

  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .bets-item {
    &.cards {
      height: 99px !important;
    }
  }
  .bet-heading {
    .bet-display-id {
      line-height: 0.8 !important;
      font-size: 20px !important;
    }

    .bet-time {
      font-size: 16px !important;
      line-height: 1 !important;
    }
  }

  .bet-content {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 1080px) and (min-height: 1920px) {
  .bets-item {
    &.cards {
      width: 50% !important;
      height: 99px !important;
    }
  }

  .bet-heading {
    .bet-display-id {
      font-size: 20px !important;
      line-height: 0.8 !important;
    }

    .bet-time {
      font-size: 16px !important;
      line-height: 1 !important;
    }
  }

  .bet-content {
    font-size: 18px !important;
  }
}
</style>
